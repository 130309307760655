/* eslint-disable class-methods-use-this */
import moment from "moment";
import AWS, { S3 } from "aws-sdk";
import { Buffer } from "buffer";

import config from "../config/config";
import { IAddress1, IPredictionsResponse } from "../interfaces/commonInterface";
import { getPlacesDetail, getPredictions } from "../services/google";

class Helper {
  /**
   * Method to capitalize a string's first character
   * @param string any string
   * @returns The capitalized string
   */
  capitalize = (string: string) =>
    string?.toLowerCase().replace(/\b[a-z]/g, (letter) => letter.toUpperCase());

  changeNumberFormate = (string: string) =>
    `+1 (${string.substring(0, 3)}) ${string.substring(
      3,
      6
    )}-${string.substring(6, string.length)}`;

  /**
   * calculate difference between two dates
   * @param pastDate date
   * @returns
   */
  diffBtwDates = (pastDate: Date) => {
    const todaysDate = moment();
    const dayDiff = todaysDate.diff(pastDate, "days");
    const monthDiff = todaysDate.diff(pastDate, "months");
    const yearDiff = todaysDate.diff(pastDate, "years");
    let dateDiff = {
      years: 0,
      months: 0,
      days: 0,
    };
    if (yearDiff > 0) {
      dateDiff = { ...dateDiff, years: yearDiff };
    }
    if (monthDiff > 0) {
      dateDiff = { ...dateDiff, months: monthDiff };
    }
    if (dayDiff > 0) {
      dateDiff = { ...dateDiff, days: dayDiff };
    }
    return dateDiff;
  };

  /**
   * Calculate actual start date-time of a job shift
   * @param date string | Date
   * @param time string | Date
   * @returns Formatted date-time
   */

  getShiftDateWithTime = (date: string | Date, time: string | Date): Date => {
    const startDate = moment(date).format("YYYY-MM-DD");
    const startTime = moment(time).format("HH:mm:ss");

    return moment(`${startDate} ${startTime}`).format() as unknown as Date;
  };
  /**
   *
   * @param  quantity number
   * @returns string
   */

  getQuantityPriceSmallSheet = (quantity: number, plan: string): number => {
    const price = { amount: 8.4 };
    if (plan === "basic")
      price.amount = quantity < 30 ? 8.95 : quantity > 74 ? 5.5 : 6.3;
    if (plan === "advance")
      price.amount = quantity < 30 ? 6.3 : quantity > 74 ? 5.5 : 6.3;
    return price.amount;
  };

  getQuantityPriceLargeSheet = (quantity: number, plan: string): number => {
    const price = { amount: 8.4 };
    if (plan === "basic")
      price.amount = quantity < 30 ? 11.95 : quantity > 74 ? 7.35 : 8.4;
    if (plan === "advance")
      price.amount = quantity < 30 ? 8.4 : quantity > 74 ? 7.35 : 8.4;

    return price.amount;
  };
  /**
   * Fetch address predictions based on searched text
   * @param searchText
   * @returns predictions
   */

  fetchPredictions = async (
    searchText: string
  ): Promise<IPredictionsResponse> =>
    new Promise((resolve, reject) => {
      try {
        (async () => {
          const response = await getPredictions(searchText);
          resolve(response?.data as IPredictionsResponse);
        })();
      } catch (e) {
        reject(e);
      }
    });

  /**
   * Fetches complete address info based on specified place id
   * @param place_id place id
   * @returns address object
   */

  onPlaceSelected = async (place_id: string) => {
    const response = await getPlacesDetail(place_id);
    let address: IAddress1 = {};
    if (response.data && response.data?.success) {
      address = { ...response.data.data };
    }
    return address;
  };

  // Upload fies on S3 bucket//

  uploadFileOnS3 = async (
    file: string,
    filePath: string,
    fileTypes: string
  ) => {
    AWS.config.update(config.aws);
    const options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
    const bucket = new AWS.S3({
      params: { Bucket: config.aws_bucket },
      useAccelerateEndpoint: true,
    });
    const buf = Buffer.from(file.split(",")[1], "base64");

    const params: S3.PutObjectRequest = {
      Body: buf,
      ContentEncoding: "base64",
      ContentType: fileTypes,
      ACL: "public-read",
      Key: filePath,
      Bucket: config.aws_bucket as string,
    };
    return new Promise((resolve, reject) => {
      bucket.upload(params, options, (s3Err: any, data: any) => {
        if (data) {
          resolve(data.Location);
        }
        if (s3Err) {
          reject(s3Err);
        }
      });
    });
  };
}

export default new Helper();
