import { http } from "../utils/http";
import ApiResponse from "../resources/entity/IApiResponse";
import { IAddress } from "../interfaces/commonInterface";
import  endpoint  from "../constant/endPoint";

/**
 * Fetching google place detail
 * @returns axios service
 */
export const getPredictions = (searchText: string): Promise<ApiResponse> =>
  http.get(`${endpoint.google.SEARCH_PREDICTION.replace(":searchText",searchText)}`);

/**
 * Fetching google place detail
 * @returns axios service
 */
export const getPlacesDetail = (placeId: string): Promise<ApiResponse> =>
  http.get(`${endpoint.google.PLACES_DETAIL.replace(":placeId",placeId)}`);

/**
 * Fetching location name detail
 * @returns axios service
 */
export const getLocationName = async (): // location: ILocation
Promise<IAddress> => {
  const response = await http.get(`${endpoint.google.PLACE_DETAILS}`);

  let address: any = {};
  if (response.data && response.data?.success) {
    address = { ...response.data.data };
  }
  return address;
};
