const ROUTES = {
  auth: {
    LOGIN: "/auth/login",
    FORGOT_PASSWORD: "/auth/send-otp",
    VERIFY_OTP: "/auth/otp-verification",
    RESET_PASSWORD: "/auth/reset-password",
    ACTIVATE_DEACTIVATE_USER: "/auth/status/update",
    SIGNUP: "/auth/signup",
    MATCH_TOKEN: "/auth/match-token",
  },

  user: {
    CONTACT_US: "/user/contact-us",
    UPDATE_PROFILE: "/user/update-profile",
    CHANGE_PASSWORD: "/user/change-password",
    UPDATE_PROFILE_IMAGE: "/user/update-profile-image",
    UPDATE_STATUS: "/user/update-status",
    GET_INFO: "/user/get-info",
    GET_USER_LIST: "/user/user-list",
    UPDATE_MEMBERSHIP_PLAN: "/user/update-plan",
    UPDATE_TAX_PLAN: "/user/update-tax",
    GET_UNREAD_NOTIFICATION: "/user/unread-notification",
    UPDATE_UNREAD_NOTIFICATION: "/user/update-notification",
    GET_USER_STATUS: "/user/user-status",
  },
  orders: {
    GET_RATE_LIST: "/order/rate-list",
    PLACE_ORDER: "/order/place-order",
    GET_ORDER_LIST: "/order/order-list",
    UPDATE_PRODUCT_STATUS: "/order/update-product",
    GET_ORDER_INFO: "/order/order-detail",
    UPDATE_ORDER_STATUS: "/order/update-order",
    USER_ORDER: "/order/my-orders",
    USER_PRODUCTS: "/order/product-list",
    UPLOAD_NEW_IMAGES: "/order/update-order-images",
    ACTIVITY_HISTORY: "/order/activity-history",
    UPLOAD_TEMP_IMAGES: "/order/create-images",
    GET_TEMP_IMAGES: "/order/get-images",
    DELETE_SINGLE_IMAGE: "/order/delete-single-image",
    UPDATE_TEMP_IMAGE_DATA: "/order/update-image-data",
  },
  payment: {
    CREATE_USER_PROFILE: "/payment/create-profile",
    USER_ORDER_PAYMENT: "/payment/order-payment",
    GET_PAYMENT_LIST: "/payment/payment-list",
    GET_FORM_TOKEN: "/payment/form-token",
  },
  uploadArtWork: {
    GET_PRESIGNEDURL: "/upload-artwork/get=presigned-url",
    PUSH_TO_S3: "/upload-artwork/upload-image",
  },
  admin: {
    DASHBOARD_COUNT: "/dashboard/dashboard-data",
    DASHBOARD_INFO: "/dashboard/dashboard-info",
  },
  notification: {
    NOTIFICATION_LIST: "/notification/notification-list",
  },
  google: {
    SEARCH_PREDICTION: "/google/get-predictions/:searchText",
    PLACES_DETAIL: "/google/get-places-details/:placeId",
    PLACE_DETAILS: "/google/location-details",
  },
};

export default ROUTES;
