import { IUserShippingInfo } from "../../interfaces/orderInterface";
import ActionType from "../../resources/enums/index";

export interface IInfoState {
  userOrderInfo: IUserShippingInfo;
}

const initialState: IInfoState = {
  userOrderInfo: {} as IUserShippingInfo,
};

const OrderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.USER_ORDER_INFO: {
      return {
        ...state,
        userOrderInfo: action?.payload,
      };
    }

    case ActionType.ORDER_EMPTY: {
      return initialState;
    }

    default:
      return state;
  }
};

export default OrderReducer;
