import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import CommonTextBox from "../commonFields/commonTextBox";

import { changePasswordValidationSchema } from "../../validationSchemas/userValidation";
import { IChangePassowrd } from "../../interfaces/userInterface";

import close from "../../assets/images/close.png";
import show from "../../assets/images/Show.svg";
import hide from "../../assets/images/Hide.svg";

type Props = {
  closeModal: () => void;
  getdata: (data: IChangePassowrd) => void;
  loading: boolean;
};

const PasswordModal: React.FC<Props> = (props) => {
  const { closeModal, getdata, loading } = props;
  const { t: translation } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordValidationSchema(translation)),
  });

  const [showOldPassword, setShowOldPassord] = useState<boolean>(false);
  const [showPassword, setShowPassord] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassord] = useState<boolean>(false);

  const onSubmit = async (data: IChangePassowrd) => {
    getdata(data);
  };

  return (
    <div
      className="modal  modal-custom-show"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body text-center p-5 close-btn">
            <button
              type="button"
              className="btn-close close-inner theme-button"
              onClick={closeModal}
            >
              <img src={close} alt="" />
            </button>

            <h4 className="mb-3 space">
              <b>
                <span className="color-theme">
                  {translation("modal_change")}
                </span>{" "}
                {translation("modal_password")}
              </b>
            </h4>
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit(data as IChangePassowrd)
              )}
              noValidate
            >
              <div className="form-group">
                <div className="double-icon-input">
                  <CommonTextBox
                    control={control}
                    name="old_password"
                    type={showOldPassword ? "text" : "password"}
                    className="form-control"
                    placeHolder={translation("old_password")}
                    error={errors.old_password}
                  />
                  <span className="left-cion">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 22 30"
                    >
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M85.389,11.25H83.556V8.75a8.558,8.558,0,1,0-17.111,0v2.5H64.611a.618.618,0,0,0-.611.625V27.5A2.475,2.475,0,0,0,66.444,30H83.556A2.475,2.475,0,0,0,86,27.5V11.875A.618.618,0,0,0,85.389,11.25ZM76.83,24.306a.636.636,0,0,1-.152.486.605.605,0,0,1-.455.208H73.778a.605.605,0,0,1-.455-.208.635.635,0,0,1-.152-.486l.386-3.546a2.445,2.445,0,1,1,2.889,0ZM79.889,11.25H70.111V8.75a4.89,4.89,0,1,1,9.778,0Z"
                        transform="translate(-64)"
                        fill="#2ebced"
                      />
                    </svg>
                  </span>
                  <span className="right-icon">
                    {showOldPassword ? (
                      <button
                        className="theme-button trans-btn"
                        type="button"
                        onClick={() => setShowOldPassord(!showOldPassword)}
                      >
                        <img src={hide} alt="" />
                      </button>
                    ) : (
                      <button
                        className="theme-button trans-btn"
                        type="button"
                        onClick={() => setShowOldPassord(!showOldPassword)}
                      >
                        <img src={show} alt="" />
                      </button>
                    )}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <div className="double-icon-input">
                  <CommonTextBox
                    control={control}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeHolder={translation("Password")}
                    error={errors.password}
                  />
                  <span className="left-cion">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 22 30"
                    >
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M85.389,11.25H83.556V8.75a8.558,8.558,0,1,0-17.111,0v2.5H64.611a.618.618,0,0,0-.611.625V27.5A2.475,2.475,0,0,0,66.444,30H83.556A2.475,2.475,0,0,0,86,27.5V11.875A.618.618,0,0,0,85.389,11.25ZM76.83,24.306a.636.636,0,0,1-.152.486.605.605,0,0,1-.455.208H73.778a.605.605,0,0,1-.455-.208.635.635,0,0,1-.152-.486l.386-3.546a2.445,2.445,0,1,1,2.889,0ZM79.889,11.25H70.111V8.75a4.89,4.89,0,1,1,9.778,0Z"
                        transform="translate(-64)"
                        fill="#2ebced"
                      />
                    </svg>
                  </span>
                  <span className="right-icon">
                    {showPassword ? (
                      <button
                        className="theme-button trans-btn"
                        type="button"
                        onClick={() => setShowPassord(!showPassword)}
                      >
                        <img src={hide} alt="" />
                      </button>
                    ) : (
                      <button
                        className="theme-button trans-btn"
                        type="button"
                        onClick={() => setShowPassord(!showPassword)}
                      >
                        <img src={show} alt="" />
                      </button>
                    )}
                  </span>
                </div>
              </div>
              <div className="form-group">
                <div className="double-icon-input">
                  <CommonTextBox
                    control={control}
                    name="confirm_password"
                    type={showConfirmPassword ? "text" : "password"}
                    className="form-control"
                    placeHolder={translation("confirm_password")}
                    error={errors.confirm_password}
                  />
                  <span className="left-cion">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 22 30"
                    >
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M85.389,11.25H83.556V8.75a8.558,8.558,0,1,0-17.111,0v2.5H64.611a.618.618,0,0,0-.611.625V27.5A2.475,2.475,0,0,0,66.444,30H83.556A2.475,2.475,0,0,0,86,27.5V11.875A.618.618,0,0,0,85.389,11.25ZM76.83,24.306a.636.636,0,0,1-.152.486.605.605,0,0,1-.455.208H73.778a.605.605,0,0,1-.455-.208.635.635,0,0,1-.152-.486l.386-3.546a2.445,2.445,0,1,1,2.889,0ZM79.889,11.25H70.111V8.75a4.89,4.89,0,1,1,9.778,0Z"
                        transform="translate(-64)"
                        fill="#2ebced"
                      />
                    </svg>
                  </span>
                  <span className="right-icon">
                    {showConfirmPassword ? (
                      <button
                        className="theme-button trans-btn"
                        type="button"
                        onClick={() =>
                          setShowConfirmPassord(!showConfirmPassword)
                        }
                      >
                        <img src={hide} alt="" />
                      </button>
                    ) : (
                      <button
                        className="theme-button trans-btn"
                        type="button"
                        onClick={() =>
                          setShowConfirmPassord(!showConfirmPassword)
                        }
                      >
                        <img src={show} alt="" />
                      </button>
                    )}
                  </span>
                </div>
              </div>
              <button
                className="theme-button primary-btn w-100"
                type="submit"
                disabled={loading}
              >
                {translation("update_password_button")}{" "}
                {loading && (
                  <div className="spinner-border text-light" role="status" />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
