import { IArtWorkData } from "../../interfaces/artworkInterface";
import ActionType from "../../resources/enums/index";

export interface IArtWorkState {
  userArtWork: IArtWorkData;
}

const initialState: IArtWorkState = {
  userArtWork: {} as IArtWorkData,
};

const ArtWorkReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.UPLOAD_ARTWORK: {
      return {
        userArtWork: action?.payload,
      };
    }
    case ActionType.CLEAR_ARTWORK: {
      return {
        userArtWork: {},
      };
    }
    default:
      return state;
  }
};

export default ArtWorkReducer;
