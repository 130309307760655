import { FC } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";

type Props = {
  errors?: FieldErrors<FieldValues>;
};

const CommonErrorText: FC<Props> = ({ errors }) => {
  return errors && errors.message ? (
    <div className="message error">
      <p>
        <span>
          <i className="fa fa-warning" />
        </span>{" "}
        {errors?.message}
      </p>
    </div>
  ) : null;
};

export default CommonErrorText;
