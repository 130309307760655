import React, { KeyboardEvent } from "react";

import { Controller, Control, FieldErrors, FieldValues } from "react-hook-form";

import CommonErrorText from "./CommonErrorText";

interface IProps {
  label?: string;
  type: string;
  className?: string;
  placeHolder?: string;
  name: string;
  value?: string | number;
  control: Control;
  error?: FieldErrors<FieldValues>;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  maxLength?: number;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onWheel?: (e: any) => void;
  hasIcon?: boolean;
  iconClass?: string;
  bottomLabel?: string;
  min?: number;
  max?: number;
}

const CommonTextBox: React.FC<IProps> = (props) => {
  const {
    label,
    type,
    className = "form-control",
    placeHolder,
    name,
    control,
    error,
    disabled = false,
    readOnly = false,
    onKeyDown,
    onClick,
    onWheel,
    maxLength,
    hasIcon,
    iconClass,
    bottomLabel,
    min,
    max,
    required,
  } = props;

  return (
    <>
      {label && (
        <label htmlFor={name} className="font14">
          {label}
          {required ? <sup className="colorRed">*</sup> : null}
        </label>
      )}
      {hasIcon ? (
        <div className="icon-input">
          <Controller
            render={({ field }) => (
              <input
                {...field}
                type={type}
                disabled={disabled}
                readOnly={readOnly}
                className={className}
                placeholder={placeHolder}
                maxLength={maxLength}
                id={name}
                min={min}
                max={max}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (onKeyDown) {
                    onKeyDown(e);
                  }
                }}
              />
            )}
            name={name}
            control={control}
          />
          <i className={iconClass} />
        </div>
      ) : (
        <div
          className="form-relative"
          onClick={() => !disabled && onClick && onClick()}
        >
          <Controller
            render={({ field }) => (
              <input
                {...field}
                type={type}
                disabled={disabled}
                readOnly={readOnly}
                className={className}
                placeholder={placeHolder}
                maxLength={maxLength}
                id={name}
                min={min}
                max={max}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (onKeyDown) {
                    onKeyDown(e);
                  }
                }}
                onWheel={(e: any) => {
                  if (onWheel) {
                    onWheel(e);
                  }
                }}
                onScroll={(e) => e.preventDefault()}
              />
            )}
            name={name}
            control={control}
          />
        </div>
      )}
      {bottomLabel && <span className="label-bottom">{bottomLabel}</span>}

      {/* Common Error message component  */}
      <CommonErrorText errors={error} />
    </>
  );
};

export default React.memo(CommonTextBox);
