import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import footerLogo from "../../assets/images/footer-logo.png";
import authorize from "../../assets/images/authorize.png";
import ROUTES from "../../constant/routes";

const Footer: React.FC = () => {
  const { t: translation } = useTranslation();
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img className="logo" src={footerLogo} alt="" />
          </div>
          <div className="col-md-2 offset-md-4">
            <h6 className="fw-6 mb-3">{translation("company")}</h6>
            <p className="op-6">
              <Link to={ROUTES.CONTACT}>{translation("contact_us")}</Link>
            </p>
            <p className="op-6">
              <Link to={ROUTES.PRIVACY_POLICY}>
                {" "}
                {translation("privacy_policy")}
              </Link>
            </p>
            <p className="op-6">
              <Link to={ROUTES.TERMS_CONDITION}>
                {translation("terms_and_condition")}
              </Link>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="header-bottom">
            <div className="d-flex justify-content-between align-items-center mt-5">
              {/* <div className="social">
                <Link to="/">
                  <div className="icon-inner me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.578"
                      height="20"
                      viewBox="0 0 15.578 30"
                    >
                      <g id="facebook-app-symbol" transform="translate(-37.29)">
                        <path
                          id="f_1_"
                          d="M47.4,30V16.317h4.591l.689-5.334H47.4V7.577c0-1.544.427-2.6,2.643-2.6h2.822V.209A38.268,38.268,0,0,0,48.755,0C44.683,0,41.9,2.485,41.9,7.049v3.933H37.29v5.334H41.9V30Z"
                          transform="translate(0)"
                        />
                      </g>
                    </svg>
                  </div>
                </Link>
                <Link to="/">
                  <div className="icon-inner me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 29.994 30"
                    >
                      <g id="instagram" transform="translate(-0.449 0)">
                        <path
                          id="Path_3371"
                          data-name="Path 3371"
                          d="M30.367,8.82a10.962,10.962,0,0,0-.7-3.639A7.685,7.685,0,0,0,25.28.791a10.99,10.99,0,0,0-3.639-.7C20.034.018,19.525,0,15.452,0S10.869.018,9.269.088a10.965,10.965,0,0,0-3.639.7A7.32,7.32,0,0,0,2.969,2.52,7.386,7.386,0,0,0,1.24,5.175a10.991,10.991,0,0,0-.7,3.639C.466,10.42.449,10.93.449,15s.018,4.583.088,6.183a10.961,10.961,0,0,0,.7,3.639,7.684,7.684,0,0,0,4.389,4.389,10.992,10.992,0,0,0,3.639.7c1.6.071,2.11.088,6.183.088s4.583-.017,6.183-.088a10.959,10.959,0,0,0,3.639-.7,7.673,7.673,0,0,0,4.39-4.389,11,11,0,0,0,.7-3.639c.07-1.6.088-2.11.088-6.183s-.006-4.583-.076-6.183Zm-2.7,12.249a8.223,8.223,0,0,1-.516,2.784A4.976,4.976,0,0,1,24.3,26.7a8.252,8.252,0,0,1-2.784.516c-1.582.071-2.057.088-6.06.088s-4.483-.017-6.06-.088A8.219,8.219,0,0,1,6.614,26.7a4.616,4.616,0,0,1-1.723-1.119,4.664,4.664,0,0,1-1.119-1.723,8.253,8.253,0,0,1-.516-2.784c-.071-1.582-.088-2.057-.088-6.06s.017-4.483.088-6.06a8.219,8.219,0,0,1,.516-2.784A4.56,4.56,0,0,1,4.9,4.448,4.657,4.657,0,0,1,6.62,3.329,8.258,8.258,0,0,1,9.4,2.813c1.582-.07,2.057-.088,6.06-.088s4.483.018,6.06.088a8.223,8.223,0,0,1,2.784.516A4.613,4.613,0,0,1,26.03,4.448a4.663,4.663,0,0,1,1.119,1.723,8.256,8.256,0,0,1,.516,2.784c.07,1.582.088,2.057.088,6.06s-.018,4.472-.088,6.054Zm0,0"
                          transform="translate(0 0)"
                        />
                        <path
                          id="Path_3372"
                          data-name="Path 3372"
                          d="M132.657,124.5a7.708,7.708,0,1,0,7.708,7.708A7.71,7.71,0,0,0,132.657,124.5Zm0,12.708a5,5,0,1,1,5-5A5,5,0,0,1,132.657,137.208Zm0,0"
                          transform="translate(-117.206 -117.205)"
                        />
                        <path
                          id="Path_3373"
                          data-name="Path 3373"
                          d="M366.047,90.4a1.8,1.8,0,1,1-1.8-1.8A1.8,1.8,0,0,1,366.047,90.4Zm0,0"
                          transform="translate(-340.785 -83.409)"
                        />
                      </g>
                    </svg>
                  </div>
                </Link>
                <Link to="/">
                  <div className="icon-inner me-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 36.91 30"
                    >
                      <path
                        id="twitter"
                        d="M36.91,51.552a15.772,15.772,0,0,1-4.36,1.2,7.527,7.527,0,0,0,3.329-4.184,15.119,15.119,0,0,1-4.8,1.832,7.566,7.566,0,0,0-13.089,5.176,7.8,7.8,0,0,0,.175,1.726A21.415,21.415,0,0,1,2.57,49.38,7.573,7.573,0,0,0,4.9,59.5a7.471,7.471,0,0,1-3.419-.932v.083a7.6,7.6,0,0,0,6.062,7.438,7.549,7.549,0,0,1-1.984.249,6.688,6.688,0,0,1-1.433-.129,7.639,7.639,0,0,0,7.071,5.273,15.2,15.2,0,0,1-9.382,3.228A14.167,14.167,0,0,1,0,74.6,21.3,21.3,0,0,0,11.608,78c13.924,0,21.537-11.538,21.537-21.54,0-.335-.012-.658-.028-.978A15.1,15.1,0,0,0,36.91,51.552Z"
                        transform="translate(0 -48)"
                      />
                    </svg>
                  </div>
                </Link>
                <Link to="/">
                  <div className="icon-inner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 30 30"
                    >
                      <g id="_x31_0.Linkedin" transform="translate(-10 -10)">
                        <path
                          id="Path_3374"
                          data-name="Path 3374"
                          d="M57.74,55.868V44.851c0-5.415-1.166-9.551-7.483-9.551a6.529,6.529,0,0,0-5.9,3.234h-.075V35.789H38.3V55.868h6.242V45.9c0-2.632.489-5.151,3.722-5.151,3.2,0,3.234,2.97,3.234,5.3V55.83H57.74Z"
                          transform="translate(-17.74 -15.868)"
                        />
                        <path
                          id="Path_3375"
                          data-name="Path 3375"
                          d="M11.3,36.6h6.242V56.679H11.3Z"
                          transform="translate(-0.811 -16.679)"
                        />
                        <path
                          id="Path_3376"
                          data-name="Path 3376"
                          d="M13.61,10a3.629,3.629,0,1,0,3.61,3.61A3.611,3.611,0,0,0,13.61,10Z"
                        />
                      </g>
                    </svg>
                  </div>
                </Link>
              </div> */}
              <div className="d-flex align-items-center justify-content-end">
                <h5 className="me-4">{translation("payment_options")}</h5>
                <img
                  className="me-2 payment-option-img"
                  src={authorize}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-sub-center">
              <p>{translation("copyright")}</p>
              <p>{translation("copyright2")}</p>
            </div>
            <div className="footer-sub-right">
              <p>{translation("developed")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
