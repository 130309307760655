const config = {
  env: process.env.NODE_ENV,
  baseUrl: process.env.REACT_APP_BASE_URL,
  productKey: process.env.REACT_APP_PRODUCT_KEY,
  aws: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  },
  aws_bucket: process.env.REACT_APP_AWS_BUCKET,
  userId_encryption_key: process.env.REACT_APP_ENCRYPT_KEY,
};

export default config;
