import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  matchRoutes,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isExpired } from "react-jwt";
import { IAuthReducerState } from "../redux/reducers/AuthReducer";
import { RootState } from "../redux/reducers/Index";

import Storage from "../utils/storage";
import toast from "../utils/toastsMessage";
import { ROLES } from "../constant/commonConstants";
import ROUTES, {
  ADMIN_ROUTES,
  ARTIST_ROUTES,
  COMMON_ROUTES,
  USER_ROUTES,
} from "../constant/routes";
import { getUserStatus } from "../services/user";
import ActionType from "../resources/enums";

interface Props {
  component: React.ComponentType;
}

const ProtectedRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const { t: translation } = useTranslation();
  const authReducer: IAuthReducerState = useSelector(
    (state: RootState) => state?.AuthReducer
  );
  const { pathname } = useLocation();
  const location = useLocation();
  const route = location?.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userRoute = matchRoutes(USER_ROUTES, pathname);
  const adminRoute = matchRoutes(ADMIN_ROUTES, pathname);
  const artistRoute = matchRoutes(ARTIST_ROUTES, pathname);
  const commonRoute = matchRoutes(COMMON_ROUTES, pathname);

  const tokenData = Storage.get("__RTA__");

  useEffect(() => {
    sessionExpire();
  }, []);

  const sessionExpire = () => {
    const isMyTokenExpired = isExpired(tokenData);

    if (isMyTokenExpired) {
      localStorage.clear();
      toast.error(translation("token-expired"));
      window.location.reload();
    }
  };

  useEffect(() => {
    if (authReducer.authData && authReducer.isLoggedIn) {
      (async () => {
        await checkUserStatus();
      })();
    }
  }, [route]);

  const checkUserStatus = useCallback(async () => {
    const responseData = await getUserStatus({
      email: authReducer?.authData.email,
    });

    if (!responseData?.data?.success) {
      localStorage.clear();
      dispatch({
        type: ActionType.LOGOUT,
      });
      toast.error(translation(`${responseData?.data?.message}`));
      navigate(ROUTES.HOME);
      window.location.reload();
    }
  }, []);

  if (tokenData && authReducer.isLoggedIn) {
    if (authReducer.authData.account_type === ROLES.USER) {
      if (userRoute?.length || commonRoute?.length) {
        return <RouteComponent />;
      }
      return (
        <h1 className="text-center">{translation("unauthorized_user")}</h1>
      );
    }
    if (authReducer.authData.account_type === ROLES.ARTIST) {
      if (artistRoute?.length) {
        return <RouteComponent />;
      }
      return (
        <h1 className="text-center">{translation("unauthorized_user")}</h1>
      );
    }
    if (authReducer.authData.account_type === ROLES.ADMIN) {
      if (adminRoute?.length) {
        return <RouteComponent />;
      }
      return (
        <h1 className="text-center">{translation("unauthorized_user")}</h1>
      );
    }
  }
  return (
    <Navigate
      to={authReducer.authData.account_type === ROLES.USER ? "/" : ROUTES.LOGIN}
    />
  );
};
export default ProtectedRoutes;
