import { IUserInterface } from "../../interfaces/userInterface";
import ActionType from "../../resources/enums/index";
import Storage from "../../utils/storage";

export interface IAuthReducerState {
  isLoggedIn: boolean;
  authData: IUserInterface;
}
const authData = Storage.get("authData")
  ? JSON.parse(Storage.get("authData") as string)
  : {};
const initialState: IAuthReducerState = {
  isLoggedIn: false,
  authData,
};

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: true,
        authData: action?.payload,
      };
    }

    case ActionType.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        authData: {},
      };
    case ActionType.EDIT_PROFILE:
      return {
        ...state,
        authData: { ...state.authData, ...action.payload },
      };
    case ActionType.EDIT_IMAGE:
      return {
        ...state,
        authData: { ...state.authData, ...action.payload },
      };
    case ActionType.USER_ACCOUNT_TYPES:
      return {
        ...state,
        authData: action.payload.data,
      };
    case ActionType.UPDATE_AUTH:
      return {
        authData: { ...state.authData, ...action.payload.data },
      };
    case ActionType.CHANGE_PASSWORD:
      return {
        ...state,
        isUpdated: action.payload.data.isUpdated,
      };
    case ActionType.CLEAR_AUTH:
      return {
        ...state,
        isRegistered: "",
        isLoggedIn: "",
        authData: {},
        isUpdated: "",
      };
    case ActionType.PENDING:
      return {
        ...state,
        isUpdated: false,
      };
    case ActionType.COMPLETED:
      return {
        ...state,
        isUpdated: true,
      };
    default:
      return state;
  }
};

export default AuthReducer;
