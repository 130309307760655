const ROUTES = {
  UNAUTHORISED: "/unauthorised",
  LOGIN: "/login",
  ADMIN_LOGIN: "/admin-login",
  FORGET_PASSWORD: "/forget-password",
  VERIFY: "/verify",
  RESET: "/reset",
  SIGN_UP: "/sign-up",
  PROFILE: "/my-profile",
  HOME: "/",
  CONTACT: "/contact",
  USER_LIST: "/users",
  ORDERS_LIST: "/order-admin",
  PLANS: "/plans",
  UPLOAD_ARTWORK: "/upload-art",
  SHIPPING_ADDRESS: "/shipping-address",
  JOB_CONFIRM: "/job-confirmation",
  PAYMENT: "/payments",
  JOB_REQUEST: "/jobs",
  DASHBOARD_ADMIN: "/dashboard-admin",
  ADMIN_ORDER_LIST: "/order-admin",
  ORDER_DETAIL: "/order-detail/:orderId",
  ARTWORK_LIST: "/artwork-list/:orderId",
  USER_ORDER_LIST: "/my-orders/:userId",
  PAYMENT_LIST: "/payment-list",
  VIEW_ORDER_STATUS: "/order-status/:orderId",
  UPDATE_ORDER_IMAGES: "/update-image/:orderId",
  UNAUTHORIZE_PAGE: "/unauthorize",
  TERMS_CONDITION: "/terms-condition",
  PRIVACY_POLICY: "/privacy",
  NOTIFICATION: "/notification",
  USER_ORDER_DETAILS: "/user-order-details/:orderId",
};

export const DONT_SHOW_AFTER_LOGIN_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.FORGET_PASSWORD,
  ROUTES.VERIFY,
  ROUTES.RESET,
  ROUTES.SIGN_UP,
  ROUTES.ADMIN_LOGIN,
];

export const COMMON_ROUTES = [
  { path: ROUTES.HOME },
  { path: ROUTES.TERMS_CONDITION },
  { path: ROUTES.PRIVACY_POLICY },
];

export const USER_ROUTES = [
  { path: ROUTES.PROFILE },
  { path: ROUTES.CONTACT },
  { path: ROUTES.PLANS },
  { path: ROUTES.UPLOAD_ARTWORK },
  { path: ROUTES.SHIPPING_ADDRESS },
  { path: ROUTES.JOB_CONFIRM },
  { path: ROUTES.PAYMENT },
  { path: ROUTES.USER_ORDER_LIST },
  { path: ROUTES.VIEW_ORDER_STATUS },
  { path: ROUTES.USER_ORDER_DETAILS },
];

export const ADMIN_ROUTES = [
  { path: ROUTES.USER_LIST },
  { path: ROUTES.ORDERS_LIST },
  { path: ROUTES.DASHBOARD_ADMIN },
  { path: ROUTES.ADMIN_ORDER_LIST },
  { path: ROUTES.ORDER_DETAIL },
  { path: ROUTES.PAYMENT_LIST },
  { path: ROUTES.NOTIFICATION },
];
export const ARTIST_ROUTES = [
  { path: ROUTES.JOB_REQUEST },
  { path: ROUTES.ARTWORK_LIST },
  { path: ROUTES.NOTIFICATION },
];

export default ROUTES;
