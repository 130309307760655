import * as http from "../utils/http";
import endpoint from "../constant/endPoint";
import ApiResponse from "../resources/entity/IApiResponse";

import {
  IChangePassowrd,
  IContactForm,
  Iupdateimage,
  UserListParms,
} from "../interfaces/userInterface";
import { IMemberShip, UserProfileData } from "../interfaces/userAuthInterface";

// Contact us
const contactUs = (data: IContactForm): Promise<ApiResponse> =>
  http.post(endpoint.user.CONTACT_US, data);

// update Profile
const updateProfile = (data: UserProfileData): Promise<ApiResponse> =>
  http.post(endpoint.user.UPDATE_PROFILE, data);

// change user password
const changePassword = (data: IChangePassowrd): Promise<ApiResponse> =>
  http.patch(endpoint.user.CHANGE_PASSWORD, data);

// update user-profile
const updateProfileImage = (data: Iupdateimage): Promise<ApiResponse> =>
  http.patch(endpoint.user.UPDATE_PROFILE_IMAGE, data);

// change user status
const changeUserStatus = (
  isActive: number,
  userId: number
): Promise<ApiResponse> =>
  http.patch(endpoint.user.UPDATE_STATUS, { isActive, userId });

// get user information
const getUserInfo = (): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_INFO);

// get User List data
const userList = async (data?: UserListParms): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_USER_LIST, data);

// update member ship plan
const updateMemberShipPlan = (data: IMemberShip): Promise<ApiResponse> =>
  http.post(endpoint.user.UPDATE_MEMBERSHIP_PLAN, data);

// update tax plan
const updateTaxPlan = (data: {
  userId: number;
  isTaxExempted: number;
}): Promise<ApiResponse> => http.post(endpoint.user.UPDATE_TAX_PLAN, data);

// get user unreadnotification count
const getUnreadNotification = (data: {
  userType: string;
}): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_UNREAD_NOTIFICATION, data);

// change notification status
const updateNotificationStatus = (data: {
  userType: string;
}): Promise<ApiResponse> =>
  http.patch(endpoint.user.UPDATE_UNREAD_NOTIFICATION, data);

// get user status
const getUserStatus = (data: { email: string }): Promise<ApiResponse> =>
  http.get(endpoint.user.GET_USER_STATUS, data);

export {
  contactUs,
  updateProfile,
  changePassword,
  updateProfileImage,
  changeUserStatus,
  getUserInfo,
  userList,
  updateMemberShipPlan,
  updateTaxPlan,
  getUnreadNotification,
  updateNotificationStatus,
  getUserStatus,
};
